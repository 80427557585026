<template>
  <div class="text-left py-3">
    <!-- <BaseHeader :title="'Clients'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class=" mt-2 border-0">
        <div class=" card d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1" style>
          <h2 class="card-title w-50 font-weight-black text-capitalize">
            Clients
          </h2>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter clients"
            name="url"
            type="text"
            hide-details
          />
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="clients"
          :search="$store.state.search"
          :hide-default-footer="true"
          :disable-pagination="true"
        >
          <template v-slot:[`item.deleted`]="{ item }">
            <v-chip
              v-if="item.status == '1'"
              color="primary"
              small
              outlined
              >Active</v-chip
            >
            <v-chip v-else color="error" small>Inactive</v-chip>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              :to="{ name: 'ClientProfile', params: { id: item.id || '' } }"
              >{{ item.id }}</router-link
            >
          </template>
          <template v-slot:[`item.names`]="{ item }">
            <router-link
              :to="{ name: 'ClientProfile', params: { id: item.id || '' } }"
              >{{ item.names }}</router-link
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              elevation="0"
              :to="{ name: 'ClientProfile', params: { id: item.id } }"
              fab
              x-small
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>

            <!-- <v-btn
            :to="{ name: 'ClientProfile', params: { id: item.id } }"
              rounded
              color="primary"
              
            >
              <v-icon left>
                mdi-eye
              </v-icon>
              View
            </v-btn> -->

            
          </template>
        </v-data-table>
        <!-- /datatable -->
        <!-- pagination -->
        <div class="card d-flex align-items-center flex-row w-100 justify-content-between p-3 mb-3" v-if="clients.length > 0">
          <p class="text-left">
            From {{ filters.from || "" }} to {{ filters.to || "" }} of
            {{ filters.total || "" }} Clients
          </p>
            <div data-v-2bb62016="" class="btn-group">
              <button   @click="navigate(filters.prev_page_url)"  v-if="filters.prev_page_url" class="btn btn-outline-success btn-lg">← &nbsp; Prev</button>
              <button   class="btn btn-success btn-lg">On Page {{ filters.current_page || "" }}</button>
              <button   @click="navigate(filters.next_page_url)" v-if="filters.next_page_url" class="btn btn-outline-success btn-lg">Next &nbsp; →</button>
            </div>
          
        </div>
        <!-- ./pagination -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Clients",
  computed: {
    ...mapState("clientusers", ["loading", "clients", "headers", "filters"]),
  },
  data() {
    return {
      countries: [],
    };
  },

  methods: {
    ...mapActions("clientusers", ["_getClients", "_navigate"]),
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this._getClients()
      .then(() => {
        console.log("done");
      })
      .catch((err) => console.log(err));
  },
};
</script>